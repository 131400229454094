import React from 'react'
import Typewriter from 'typewriter-effect';
import bg from '../../images/audits_header.jpg'
import { VscGraph } from "react-icons/vsc";
import { RiTeamFill } from "react-icons/ri";
import { MdInsights } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import GetInTouch from './GetInTouch';

const Slider = () => {

    return (
        <>
            <div className="finance-banner" style={{ backgroundImage: `url('${bg}')` }}>
                <div className="container">
                    <div className="space-70"></div>
                    <div className="row">
                        <div className="col-lg-7 pr-xl-0">
                            <h2>All-in-One<br /> Platform For<br />
                                <span style={{ color: "#008000" }}>
                                    <Typewriter
                                        options={{
                                            strings: ['E-Commerce','Audits', 'Asset Management', 'HRIS'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />
                                </span>
                            </h2>
                            <div className="ot-div">
                            <GetInTouch />
                            <div className="box-s1 icon-left bline-no">
                                <div className="icon-box icon-box-2">
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="space-120"></div>
                </div>
            </div>
            <div className='feature'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 mb-2 mb-md-0">
                                <NavLink className={"text-decoration-none"} to="">
                                    <div className="icon-box icon-box-2 feature-box ">
                                        <div className="icon-main threecard">
                                            <VscGraph />
                                        </div>
                                        <div className="content-box">
                                            <h3 className="title-box">Collect Data</h3>
                                            <p>Create and assign powerful data collection workflows.</p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-2 mb-md-0">
                                <NavLink className={"text-decoration-none"} to="">
                                    <div className="icon-box icon-box-2 feature-box ">
                                        <div className="icon-main threecard">
                                            <RiTeamFill />
                                        </div>
                                        <div className="content-box">
                                            <h3 className="title-box">Guide Teams</h3>
                                            <p>Guide task completion on mobile with context for teams</p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-2 mb-md-0">
                                <NavLink className={"text-decoration-none"} to="">
                                    <div className="icon-box icon-box-2 feature-box">
                                        <div className="icon-main threecard">
                                            <MdInsights />
                                        </div>
                                        <div className="content-box">
                                            <h3 className="title-box">Gain Clarity</h3>
                                            <p>Quickly Derive Solutions and Automate Actions</p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>

                        </div>
                    </div>
                </div>
        </>
    )
}

export default Slider
