import axios from 'axios';

const Apis = () => {
    axios.defaults.baseURL = "https://admin.shopersbay.com/";

    axios.defaults.headers.common['authentication'] = "651de5c31c89cf52adfa9a3b64a62db2";
    // axios.defaults.headers.common['authentication'] = "21e7ac68af78a3be93bbf71958f216d9";

    const getMasterDataService = async () => {
        const response = await axios.get("asapi/getMasterData")
        return response;
    };

    const getNavigationlist = async () =>{
        const response =  await axios.get("asapi/getNavigationList");
        return response;
    }

    const getHomeDatalist = async () =>{
        const response =  await axios.get("asapi/getHomePageNewsData");
        return response;
    }

    const getDocumentationlist = async () =>{
        const response =  await axios.get("asapi/getDocumentData");
        return response;
    }

    const SearchDocument = async (input_data) => {
        const response = await axios.post("asapi/searchPost", input_data);
        return response;
    };

    const SaveContactForm = async (input_data) => {
        const response = await axios.post("asapi/saveContact", input_data);
        return response;
    };

    const getSubscriptionlist = async () => {
        const response = await axios.get("asapi/getSubscription");
        return response;
    }

    const getFaqList = async () => {
        const response = await axios.get("asapi/getFaqList");
        return response;
    }

    const getBlogdetail = async (input_data) => {
        const response = await axios.post("asapi/getPostsDetail", input_data);
        return response;
    }

    return {
        getMasterDataService: getMasterDataService,
        getNavigationlist: getNavigationlist,
        getDocumentationlist: getDocumentationlist,
        SearchDocument: SearchDocument,
        getHomeDatalist: getHomeDatalist,
        SaveContactForm: SaveContactForm,
        getSubscriptionlist: getSubscriptionlist,
        getFaqList: getFaqList,
        getBlogdetail: getBlogdetail,

    }
}

export default Apis
