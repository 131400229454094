import React from 'react'
import pic from '../../images/img1.jpg'

const OneImage = ({data}) => {
    return (
        <>
            <div class="business-bg-light">
                <div class="container">
                    <div class="space-60 d-none d-md-block"></div>
                    <div class="space-60"></div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 mb-5 mb-lg-0">
                            <img src={pic} alt="" class="rounded-img" />
                        </div>
                        <div class="col-lg-6">
                            <div class="business-about-desc">
                                <div class="ot-heading">
                                    <span class="">{data.title}</span>
                                    <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
                                </div>
                                <div class="space-20"></div>
                                <div class="space-5"></div>
                                <div dangerouslySetInnerHTML={{ __html: data.body }}></div>
                                <div class="space-20"></div>
                            </div>
                        </div>
                    </div>
                    <div class="space-60 d-none d-md-block"></div>
                    <div class="space-60"></div>
                </div>
            </div>
        </>
    )
}

export default OneImage
